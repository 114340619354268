import axios from "axios";

import { HUBSPOT_FORM_ID, HUBSPOT_PORTAL_ID, API_URI } from "../../config";

const PORTAL_ID = '5511705';
const FORM_ID = 'c25fd1ab-9391-481e-ba6e-83b324d5dcf7';

const hubspotSubmit = async ( formData: IAnswers ) => {

    const hubspotFields = []
    
    for (const key in formData) {
        const answer = { name: key, value: formData[key] }
        hubspotFields.push(answer) 
    }

    try {
        // ============ Sending to Hubspot ============ //
        const response: IHubspotResponse = await axios.request({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          url: `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`,
          data: JSON.stringify({
            fields: hubspotFields,
          }),
        });

        return response
      
    } catch (error) {
        return error as IHubspotResponse

    }

}

export default hubspotSubmit

interface IAnswers {
    [key: string]: string
}

interface IHubspotResponse { 
  data: any
  status: number
}
