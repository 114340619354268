import React, { ReactFragment, useState, useRef } from "react";
import Slider, { LazyLoadTypes } from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import hubspotSubmit from './hubspotSubmit'

import { Container } from "../../styles/commons";

import { AnimatedTitle } from "../../components/commons/AnimatedTitle";

import bgAnimation from "../../animations/contact-form/splash-03.json"

import { ContactUsBackground, SlidesContainer, SlideContainer, SlideTitle,  ContactUsCTA, SiderButtonsContainer, SlideButton, StyledTextField } from "./styles";

import rightArrow from "../../images/contactUs/001-arrow-pointing-to-right.svg";
import leftArrow from "../../images/contactUs/002-arrow-pointing-to-left.svg";
import sendArrow from "../../images/contactUs/wI-RIuts.png"
import { LottieBg } from "../../components/commons/lottieBg";

export const ContactUs: React.FunctionComponent<IContactUs>  = (props) => {
    const [currentStep, setCurrentStep] = useState(-1);
    const [answers, setCurrentAnswers] = useState<IAnswers>({})
    const [answersErrors, setCurrentAnswerErrors] = useState<IAnswers>({})
    const [ submitError, setSubmitError ] = useState(false)

    const slider = useRef(null)
    const inputRef = useRef(null);

    const inputValidator = ()=>{
        switch (currentStep) {
            case -1: // CTA Button
                return true
            case 0: // CTA Button
                return true
            case 1: // Name
                if (!answers.firstname || answers.firstname === "" || answers.firstname === " " ) {
                    setCurrentAnswerErrors(prev => {
                        return {...prev, firstname: "Name field can't be empty"}
                    })
                    return false
                }
                if (/[<>{}\-*\/()=1-9;]/gm.test(answers.firstname)) {
                    setCurrentAnswerErrors(prev => {
                        return {...prev, firstname: "Name can't contain special characters or numbers"}
                    })
                    return false
                }
                if(answers.firstname.length > 50) {
                    setCurrentAnswerErrors(prev => {
                        return {...prev, firstname: "Name can't contain more than 50 characters"}
                    })
                    return false
                }

                setCurrentAnswerErrors(prev => {
                    const {name, ...newState} = prev
                    return newState
                })
                return true

            case 2: // Email
                if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(answers.email)) {
                    setCurrentAnswerErrors(prev => {
                        return {...prev, email: "Please use a valid mail"}
                    })
                    return false
                }

                setCurrentAnswerErrors(prev => {
                    const {email, ...newState} = prev
                    return newState
                })
                return true

            case 3: // Phone Number
                if (answers.phone?.length > 0 && !/^[0-9\s-]*$/.test(answers.phone)) {
                    setCurrentAnswerErrors(prev => {
                        return {...prev, phone: "Only numbers are allowed"}
                    })
                    return false
                }
                setCurrentAnswerErrors(prev => {
                    const {phone, ...newState} = prev
                    return newState
                })
                return true

            case 4: // Message
                if(answers.message?.length > 500) {
                    setCurrentAnswerErrors(prev => {
                        return {...prev, firstname: "Message can't contain more than 500 characters"}
                    })
                return false
            }
                setCurrentAnswerErrors(prev => {
                    const {message, ...newState} = prev
                    return newState
                })
                return true

            default:
                return true 
        }
    }

    const handleNext = async () => {
        if (inputValidator()) {
            if(currentStep === 4) {
                // send data
                const response = await hubspotSubmit(answers)
                if (response?.status !== 200) {
                    setSubmitError(true)
                } 
            }
            slider.current.slickNext()
        }
    }

    const handlePrev = () => {
        slider.current.slickPrev()
    }

    const handleAnswerChange = (question: string, answer: string): void => {
        setCurrentAnswers(prevAnswers => {
            let newAnswers = {...prevAnswers}
            newAnswers[question] = answer
            return newAnswers
        })
    }

    const sliderSettings = {
        dots: false,
        arrows: false,
        swipeToSlide: false,
        lazyLoad: "progressive" as LazyLoadTypes,
        swipe: false,
        infinite: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current: number, next: number) => {
            setCurrentStep(next)
        },
        afterChange: (current: number) => {
            inputRef.current?.focus()
        }
    };

    const SliderControlers: (isCurrent:boolean, skip?:boolean) => ReactFragment = (isCurrent, skip=false) => (
        <>
            <SiderButtonsContainer>
                <SlideButton onClick={handlePrev} tabIndex={isCurrent ? 0 : -1}>
                    <img src={leftArrow} alt="previous step" />
                </SlideButton>
                {
                    skip &&
                    <SlideButton onClick={handleNext} tabIndex={isCurrent ? 0 : -1}>
                        skip
                    </SlideButton>
                }
                <SlideButton id={currentStep === 4 ? 'contact-us-submit' : 'contact-us-next'} onClick={handleNext} tabIndex={isCurrent ? 0 : -1}>
                    {
                        currentStep === 4 ?
                        <img id="contact-icon-submit" src={sendArrow} alt="submit" />
                        : <img id="contact-icon-next" src={rightArrow} alt="next step" />
                    }
                </SlideButton>
            </SiderButtonsContainer>
        </>
    )
          
    return (
        <ContactUsBackground backgroundColor={props.backgroundColor}>
            <LottieBg animation={bgAnimation}/>
            <Container>
                <SlidesContainer >
                    <Slider {...sliderSettings} ref={slider}  >
                        <SlideContainer tabIndex={-1}>
                            <SlideTitle>
                            <AnimatedTitle offset="0%" height="calc(40px + 4vw)" heightMobile="17vw">
                                Let's partner for success
                            </AnimatedTitle>
                            </SlideTitle>
                            <ContactUsCTA onClick={handleNext} ref={currentStep === 0 ? inputRef : null} >
                                GET IN TOUCH
                            </ContactUsCTA>
                        </SlideContainer>
                        <SlideContainer tabIndex={-1} >
                            <SlideTitle>My name is</SlideTitle>
                            <StyledTextField
                                value={answers.firstname || ""}
                                onChange={e => handleAnswerChange("firstname", e.currentTarget.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleNext()
                                    }
                                }}
                                aria-label="firstname"
                                hiddenLabel
                                variant="filled"
                                placeholder="Sally Jhonson"
                                error={answersErrors.firstname ? true : false}
                                helperText={answersErrors.firstname}
                                inputRef={currentStep === 1 && inputRef}
                                tabIndex={-1}
                                inputProps={
                                    {
                                        tabIndex:-1
                                    }
                                }
                            />


                            {SliderControlers(currentStep === 1)}
                        </SlideContainer>
                        <SlideContainer tabIndex={-1}>
                            <SlideTitle>My email address is</SlideTitle>
                            <StyledTextField
                                value={answers.email}
                                onChange={e => handleAnswerChange("email", e.currentTarget.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleNext()
                                    }
                                }}
                                type="email"
                                aria-label="email"
                                hiddenLabel
                                variant="filled"
                                placeholder="sallyjhonson@mail.com"
                                error={answersErrors.email ? true : false}
                                helperText={answersErrors.email}
                                inputRef={currentStep === 2 && inputRef}
                                tabIndex={-1}
                                inputProps={
                                    {
                                        tabIndex:-1
                                    }
                                }
                            />
                            {SliderControlers(currentStep === 2)}
                        </SlideContainer>
                        <SlideContainer tabIndex={-1}>
                            <SlideTitle>My telephone number is</SlideTitle>
                            <StyledTextField
                                value={answers.phone}
                                onChange={e => handleAnswerChange("phone", e.currentTarget.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleNext()
                                    }
                                }}
                                type="phone"
                                aria-label="phone"
                                hiddenLabel
                                variant="filled"
                                placeholder="123456789"
                                error={answersErrors.phone ? true : false}
                                helperText={answersErrors.phone}
                                inputRef={currentStep === 3 && inputRef}
                                tabIndex={-1}
                                inputProps={
                                    {
                                        tabIndex:-1
                                    }
                                }
                            />
                            {SliderControlers(currentStep === 3, true)}
                        </SlideContainer>
                        <SlideContainer isMessage tabIndex={-1}>
                            <SlideTitle lessMarginBottom>Contact reason</SlideTitle>
                            <StyledTextField
                                value={answers.message}
                                onChange={e => handleAnswerChange("message", e.currentTarget.value)}
                                // Code to submit with enter instead of break-line
                                // onKeyDown={(e) => {
                                //     if (e.key === 'Enter') {
                                //         handleNext()
                                //     }
                                //   }}
                                aria-label="message"
                                hiddenLabel
                                variant="filled"
                                placeholder="message"
                                multiline
                                rows={4}
                                error={answersErrors.message ? true : false}
                                helperText={answersErrors.message}
                                inputRef={currentStep === 4 && inputRef}
                                tabIndex={-1}
                                inputProps={
                                    {
                                        tabIndex:-1
                                    }
                                }
                            />
                            {SliderControlers(currentStep === 4)}
                        </SlideContainer>
                        <SlideContainer tabIndex={-1} id="contact">
                            {
                                submitError ? (
                                    <>
                                        <SlideTitle>
                                            We found an error
                                        </SlideTitle>
                                        <SlideTitle>
                                            please try sending the form again
                                        </SlideTitle>
                                    </>
                                )
                                : (
                                    <>
                                        <SlideTitle>
                                            Thank you
                                        </SlideTitle>
                                        <SlideTitle>
                                            we'll be in touch soon!
                                        </SlideTitle>
                                    </>
                                )
                            }
                        </SlideContainer>    
                    </Slider>
                </SlidesContainer>
            </Container>
        </ContactUsBackground>
    )
};

interface IAnswers {
    [key: string]: string
}

interface IContactUs {
    backgroundColor?: string
}
