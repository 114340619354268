import styled from "styled-components";
import { styled as MuiStyled } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { TABLET_1023, MOBILE_460 } from "../../styles/sizes";

export const ContactUsBackground = styled.div<IdivProps>`
    background: rgb(239,85,136);
    background: linear-gradient(0deg, rgb(237,25,137)  65%, rgba(255,255,255,0) 70%);
    z-index: 0;
    height: calc(300px + 10vw);
    padding-top: calc(75px + 3vw);
    overflow: hidden;
    position: relative;
    background-color: ${({backgroundColor}) => backgroundColor && backgroundColor };
    @media (max-width: ${TABLET_1023}px){
        padding-top: 75px;
    }
    @media (max-width: ${MOBILE_460}px){
        padding-top: 50px;
        height: 300px;
    }
`

export const SlidesContainer = styled.div`
    padding: 60px 0 0 0;
    width: 100%;

    @media (max-width: ${MOBILE_460}px){
        padding: 20px 0 0 0;

        .slick-track{
            display: flex;
            align-items: center;
        }
    }
`

export const SlideContainer = styled.div<IdivProps>`
    width: 100%;
    min-width: 100%;
    //margin-bottom: ${({isMessage}) => isMessage && "20px" };
    margin-bottom: 40px;
`

export const SlideTitle = styled.div<IdivProps>`
    font-family: "Montserrat";
    font-size: calc(10px + 2.5vw);
    line-height: calc(15px + 3vw);
    font-variation-settings: 'wght' 650;
    color: white;
    margin-bottom: ${({lessMarginBottom}) => lessMarginBottom ? "17px" : "20px" };
    margin-top: 10px;
`

export const ContactUsCTA = styled.button`
    background: transparent;
    border: 2px solid white;
    border-radius: 5px;
    color: white;
    font-family: "Montserrat";
    font-size: 25px;
    line-height: 30px;
    font-variation-settings: 'wght' 600;
    padding: 15px 60px;
    transition: 0.5s;

    & :hover, & :focus {
        color: red;
        background: white;
    }
    @media (max-width: ${TABLET_1023}px){
        font-size: 18px;
        line-height: 30px;
        padding: 10px 40px;
    }
    @media (max-width: ${MOBILE_460}px){
        font-size: 15px;
        line-height: 20px;
        width: 100%;
        border-radius: 0;

    }
`

export const SiderButtonsContainer = styled.div`
    padding: 15px 0;
    @media (max-width: ${MOBILE_460}px){
        padding: 7px 0;
    }
`
export const SlideButton = styled.button`
    background: transparent;
    border: none;
    min-width: 50px;
    margin: 0 25px;
    display: inline-block;
    color: white;
    font-family: "Montserrat";
    font-size: 25px;
    line-height: 30px;
    vertical-align: middle;
    font-variation-settings: 'wght' 600;

    & :focus, & :hover {
        transform: scale(1.3);
        transition: 0.33s;
    }

    @media (max-width: ${MOBILE_460}px){
        margin: 0 15px;
        font-size: 15px;
        line-height: 20px;
        padding: 3px 10px;
        min-width: 40px;
    }

    & > img {
        width: 50px;
        @media (max-width: ${MOBILE_460}px){
            width: 40px;
        }
    }


`

export const StyledTextField = MuiStyled(TextField)({
    width: "450px",
    boder: "none",
    textAlign: "center",
    backgroundColor: "rgba(0,0,0,0.6)",
    '& > .MuiFilledInput-underline:after, & > .MuiFilledInput-underline:before': {
        borderBottom: "0px",
        border: "none"
    },
    '& .MuiInputBase-input, & .MuiInputBase-input::placeholder ': {
        color: "white",
        fontFamily: "Montserrat",
        fontSize: "25px",
        lineHeight: "30px",
        fontVariationSettings: "'wght' 600",
        textAlign: "center",
        '@media (max-width:1023px)': {
            fontSize: "20px",
            lineHeight: "25px",
        },
        '@media (max-width:460px)': {
            fontSize: "15px",
            lineHeight: "20px",
        },
    },
    '& .MuiFilledInput-multiline': {
        padding: "10px",
    },
    '@media (max-width:1023px)': {
        width: "330px",
    },
    '@media (max-width:460px)': {
        width: "250px",
    }
});


export const LottieBackground = styled.div`
    z-index: -1;
    position: absolute;
    top: 6px;
    width: 100vw;
    @media (max-width: ${TABLET_1023}px){
        width: 1023px;
    }
    @media (max-width: ${MOBILE_460}px){
        width: 600px;
    }
`
interface IdivProps {
    isMessage?: boolean,
    lessMarginBottom?: boolean,
    backgroundColor?: string
}
